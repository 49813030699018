import React from 'react'

/** @jsx jsx */
import { jsx  } from 'theme-ui'

export const SocialMediaGrid = ({ accounts }) => {
  console.log(accounts)
  return (
    <div>
      {accounts?.length > 0 && (
              <ul
        sx={{
          listStyle: `none`,
          m: 0,
          p: 0,
          display: `flex`,
        }}
      >
        {accounts.map((account) => {
          return (
            <li
              key={account._key}
              sx={{
                pr: 3
              }}
            >
              <a href={account.link} sx={{
                color: `inherit`,
                textDecoration: `none`,
                pb: 1,
                borderBottom: `2px solid`,
              }}
                target="_blank"
                rel="nooponer noreferrer nofollow"
              >{account.network}</a>
            </li>
          )
        })}
      </ul>
      )}
    </div>
  )
}