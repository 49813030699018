import React from 'react'
import { Link } from 'gatsby'
import GatsbyImage from 'gatsby-image'
/** @jsx jsx */
import { jsx, Styled, Text } from 'theme-ui'
import { FaLongArrowAltRight } from 'react-icons/fa'

import { mediaQueries } from '../gatsby-plugin-theme-ui/media-queries'
export const BlogPostPreviewGridLatest = ({ latestPosts, className }) => {
  return (
    <div
      className={className}
    >
      <div
        sx={{
          borderBottom: `1px solid`,
          borderColor: `grey`,
          py: 3,
          mb: 4
        }}
      >
        <h2 sx={{ m: 0, variant: 'text.heading.small' }}>Latest blog posts</h2>
      </div>
      <ul
        sx={{
          listStyle: `none`,
          margin: 0,
          padding: 0,
          display: 'grid',
          gridGap: 5,
          [mediaQueries.xl]: {
            gridTemplateColumns: 'repeat(2, 1fr)',
            gridColumnGap: 5,
            gridRowGap: 5
          }
        }}
      >
        {latestPosts &&
          latestPosts.map((post, id) => {
            if (id === 0) {
              return (
                <li
                  key={id}
                  sx={{
                    [mediaQueries.xl]: {
                      gridRow: `1 / span 3`
                    }
                  }}
                >
                  <Link
                    sx={{
                      display: `block`,
                      mb: 3
                    }}
                    to={post.path}
                  >
                    {post.mainImage?.asset?.fluid && (
                      <GatsbyImage fluid={post.mainImage?.asset?.fluid} alt={post.mainImage?.alt} />
                    )}
                  </Link>
                  <Text as="span" sx={{ fontSize: 2, color: 'text' }}>
                    {post.publishedAt}
                  </Text>
                  <Link
                    to={post.path}
                    sx={{
                      textDecoration: `none`,
                      ':hover': {
                        '> *': {
                          color: 'primary'
                        }
                      }
                    }}
                  >
                    <Styled.h3 sx={{ mt: 2, mb: 2, fontWeight: `bold`, fontSize: [4, 4, 4, 4, 5] }}>
                      {post.title}
                    </Styled.h3>
                  </Link>
                  <Styled.p sx={{ mt: 0 }}>{post.excerpt}</Styled.p>
                </li>
              )
            } else {
              return (
                <li
                  key={id}
                  sx={{
                    [mediaQueries.xl]: {
                      gridColumn: `2 / auto`
                    }
                  }}
                >
                  <div
                    sx={{
                      display: `flex`,
                      flexDirection: `column`,
                      [mediaQueries.xl]: {
                        flexDirection: `row`
                      }
                    }}
                  >
                    <div
                      sx={{
                        [mediaQueries.xl]: {
                          flex: 2,
                          mr: 3
                        }
                      }}
                    >
                      <Link to={post.path} sx={{ textDecoration: `none`, color: `initial` }}>
                        {post.mainImage && (
                          <GatsbyImage
                            fluid={post.mainImage?.asset?.fluid}
                            alt={post.mainImage?.alt}
                          />
                        )}
                      </Link>
                    </div>
                    <div
                      sx={{
                        [mediaQueries.xl]: {
                          flex: `3`
                        }
                      }}
                    >
                      <Link
                        to={post.path}
                        sx={{
                          textDecoration: `none`,
                          ':hover': {
                            '> *': {
                              color: 'primary'
                            }
                          }
                        }}
                      >
                        <Styled.h3
                          sx={{ mt: 2, mb: 2, fontWeight: `bold`, fontSize: [4, 4, 4, 4, 4] }}
                        >
                          {post.title}
                        </Styled.h3>
                      </Link>
                      <Styled.p sx={{ mt: 0, fontSize: [2, 2, 2, 2, 2, 2] }}>
                        {post.excerpt}
                      </Styled.p>
                      <div
                        sx={{
                          display: `flex`,
                          justifyContent: `space-between`
                        }}
                      >
                        <Text as="span" sx={{ fontSize: 2, color: 'text', mr: 5, opacity: 0.5 }}>
                          {post.publishedAt}
                        </Text>
                        <Link
                          to={post.path}
                          sx={{
                            mt: 0,
                            fontSize: [2, 2, 2, 2, 2, 2],
                            color: 'text',
                            textDecoration: `none`,
                            display: `flex`,
                            alignItems: `center`,
                            ':hover': {
                              color: 'primary'
                            }
                          }}
                        >
                          Read more
                          <FaLongArrowAltRight sx={{ ml: 3 }} />
                        </Link>
                      </div>
                    </div>
                  </div>
                </li>
              )
            }
          })}
      </ul>
    </div>
  )
}
