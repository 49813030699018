import React from 'react'
import { Section } from './section'

/** @jsx jsx */
import { jsx, Text, Container } from 'theme-ui'
import { mediaQueries } from '../../gatsby-plugin-theme-ui/media-queries'

export const SectionGridCardSelection = ({ section, ...rest }) => {
  const { cards, settings } = section

  return (
    <Section variant={settings?.theme} {...rest}>
      <Container
        variant="container.large"
        sx={{
          px: 4,
          py: 5,
          margin: `0 auto`,
          [mediaQueries.xl]: {
            px: 6,
            py: 5,
          },
          [mediaQueries.xxl]: {
            px: 7,
          },
        }}
      >
        {section?.heading?.text && (
          <div
            sx={{
              borderBottom: `1px solid`,
              borderColor: `grey`,
              py: 3,
              mb: 4,
            }}
          >
            <Text
              as={section?.heading?.headingType || `h2`}
              sx={{
                color: `inherit`,
                m: 0,
                textAlign: section?.heading?.textAlignment,
              }}
              variant={`heading.${section?.heading?.size}`}
            >
              {section?.heading?.text}
            </Text>
          </div>
        )}
        {cards?.length > 0 && (
          <ul
            sx={{
              listStyle: `none`,
              p: 0,
              display: `grid`,
              gridGap: 3,
              [mediaQueries.xl]: {
                gridTemplateColumns: `repeat(${cards?.length}, 1fr)`,
              },
            }}
          >
            {cards.map((card) => {
              return (
                <li key={card._key}>
                  <Card title={card.title} />
                </li>
              )
            })}
          </ul>
        )}
      </Container>
    </Section>
  )
}

const Card = ({ title, link, theme }) => {
  return (
    <div
      sx={{
        bg: `primary`,
        color: `black`,
        borderRadius: `16px`,
        height: `128px`,
        display: `flex`,
        justifyContent: `center`,
        alignItems: `center`,
      }}
    >
      <span
        sx={{
          fontWeight: `bold`,
          textTransform: `uppercase`,
          letterSpacing: `1.2px`,
        }}
      >{title}</span>
    </div>
  )
}