import React from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import GatsbyImage from 'gatsby-image'
import { FaLongArrowAltRight } from 'react-icons/fa'

/** @jsx jsx */
import { jsx, Container, Styled, Text } from 'theme-ui'

import { mapEdgesToNodes } from '../../lib/helpers'
import { BlogPostPreviewGridLatest } from '../blog-post-preview-grid-latest'
import { Section } from './section'
import { mediaQueries } from '../../gatsby-plugin-theme-ui/media-queries'
import ButtonLink from '../button-link'

const postsQuery = graphql`
  query {
    posts: allSanityPost(limit: 12, sort: { fields: [publishedAt], order: DESC }) {
      edges {
        node {
          id
          path
          publishedAt(formatString: "MMMM DD, YYYY")
          mainImage {
            asset {
              _id
              fluid(maxWidth: 600, maxHeight: 400) {
                ...GatsbySanityImageFluid_noBase64
              }
            }
            alt
          }
          title
          excerpt
          slug {
            current
          }
        }
      }
    }
  }
`
export const SectionBlogPosts = ({ settings, layout }) => {
  const data = useStaticQuery(postsQuery)

  const postNodes = data?.posts && mapEdgesToNodes(data.posts)
  const latestPosts = postNodes?.slice(0, 4)

  return postNodes ? (
    <Section variant={settings?.theme}>
      <Container
        variant="container.large"
        sx={{
          px: 4,
          py: 5,
          pt: 0,
          margin: '0 auto',
          [mediaQueries.xl]: {
            px: 6,
            py: 6,
          },
          [mediaQueries.xxl]: {
            px: 7,
            py: 6,
          },
        }}
      >
        {layout === `listView` ? (
          <BlogPostPreviewList posts={latestPosts} />
        ) : (
          <BlogPostPreviewGridLatest latestPosts={latestPosts} />
        )}
        <div
          sx={{
            display: `flex`,
            justifyContent: `center`,
            mt: 6,
          }}
        >
          <ButtonLink variant="black" to="/blog/">
            See all posts
          </ButtonLink>
        </div>
      </Container>
    </Section>
  ) : null
}

const BlogPostPreviewList = ({ posts }) => {
  return (
    <div
      sx={{
        [mediaQueries.lg]: {
          display: `grid`,
          gridTemplateColumns: `3fr 1fr`,
        },
      }}
    >
      <ul
        sx={{
          listStyle: `none`,
          padding: 0,
          margin: 0,
          display: `grid`,
          gridGap: 4,
        }}
      >
        {posts?.length > 0 &&
          posts.map((post, id) => {
            return (
              <li key={id}>
                <div
                  sx={{
                    display: `flex`,
                    flexDirection: `column`,
                    [mediaQueries.xl]: {
                      flexDirection: `row`,
                    },
                  }}
                >
                  <div
                    sx={{
                      [mediaQueries.xl]: {
                        flex: 2,
                        mr: 4,
                      },
                    }}
                  >
                    <Link to={post.path} sx={{ textDecoration: `none`, color: `initial` }}>
                      {post.mainImage?.asset?.fluid && (
                        <GatsbyImage
                          fluid={post.mainImage?.asset?.fluid}
                          alt={post.mainImage?.alt}
                        />
                      )}
                    </Link>
                  </div>
                  <div
                    sx={{
                      [mediaQueries.xl]: {
                        flex: `3`,
                      },
                    }}
                  >
                    <Text as="span" sx={{ fontSize: 2, color: 'text' }}>
                      {post.publishedAt}
                    </Text>
                    <Styled.h3 sx={{ mt: 2, mb: 2, fontWeight: `bold`, fontSize: [4, 4, 4, 4, 4] }}>
                      {post.title}
                    </Styled.h3>
                    <Styled.p sx={{ mt: 0 }}>{post.excerpt}</Styled.p>
                    <Link
                      to={post.path}
                      sx={{
                        mt: 0,
                        fontSize: [2, 2, 2, 2, 2, 2],
                        color: 'text',
                        textDecoration: `none`,
                        display: `flex`,
                        alignItems: `center`,
                        ':hover': {
                          color: 'primary',
                        },
                      }}
                    >
                      Read more
                      <FaLongArrowAltRight sx={{ ml: 3 }} />
                    </Link>
                  </div>
                </div>
              </li>
            )
          })}
      </ul>
      <div />
    </div>
  )
}