import React from 'react'
import { CopyBlock, atomOneDark } from 'react-code-blocks'
// @jsx jsx

import { jsx } from 'theme-ui'

export const CodeSnippet = ({ code, language, highlight, showLineNumbers = false }) => {

  console.log(code)
  return (
    <div
      sx={{
        code: {
          fontFamily: `monospace !important`,
        }
      }}
    >
      <CopyBlock
        text={code}
        language={language}
        showLineNumbers={showLineNumbers}
        theme={atomOneDark}
        wrapLines
        codeBlock
        highlight={highlight}
      />
    </div>
  )
}